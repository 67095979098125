form {
  label {
    font-size: 12px;
    color: $dark-grey;
    text-transform: uppercase;
  }

  input:not([type='checkbox']),
  select,
  textarea {
    padding: 13px;
    margin: 6px 0 0 0;
    width: 100%;
    display: inline-block;
    border: 1px solid $light-grey;
    font-size: 16px;
    background-color: rgba(255,255,255,.15);
    border: 1px solid rgba(255,255,255,.75);
    border-radius: 6px;
    color: #ffffff;

    &.has-error {
      border: 2px solid red;
      outline-color: red;
    }

    &:disabled {
      background-color: $lightest-grey;
      cursor: not-allowed;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(255,255,255,.75);
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #283784 inset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }

  .error-message {
    color: #eee;
    opacity: 1;
    font-size: 14px;
    display: inline-block;
    float: right;
    height: 0px;
    margin-top: -2px;
    text-align: right;
    max-width: 80%;

    &.error-block {
      display: block;
      height: auto;
      float: none;
      text-align: left;
      max-width: none;
      margin: 0px;
    }
  }

  input[type='checkbox'] {
    display: none;

    & + label {
      position: relative;
      text-transform: none;
      padding-left: 30px;
      display: inline-block;
      margin-bottom: 16px;
      font-size: 16px;
    }

    & + label:before,
    & + label:after {
      position: absolute;
    }

    & + label:before {
      content: '';
      top: -3px;
      left: 0;
      display: inline-block;
      height: 20px;
      width: 20px;
      border: 1px solid $light-grey;
    }

    &:checked + label:after {
      content: '';
    }

    & + label:after {
      content: none;
      top: 3px;
      left: 5px;
      display: inline-block;
      height: 6px;
      width: 10px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
    }
  }

  select {
    padding-right: 15px;
    border-radius: 0;
    -webkit-appearance: none;
    background: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>')
      no-repeat 96% 50%;
    height: 47px;
  }

  .form-show-password {
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255,255,255,0.7);
    opacity: .5; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255,255,255,0.7);
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255,255,255,0.7);
  }

}
