.Hero {
	background: #293884;
	padding: 150px 8px 94px 8px;
	background-image: url('../assets/img/hero-background-couple-beach.jpg');
	background-size: cover;
	background-position: 64% 60%;
	min-height: 300px;
	color: #ffffff;

	@include down('sm') {
		padding: 124px 8px 56px 8px;
	}

	h1 {
		font-size: 36px;

		@include down('md') {
			font-size: 28px;
		}
	}

	h2 {
		font-size: 24px;

		@include down('md') {
			font-size: 20px;
		}
	}

	p {
		font-size: 16px;
	}

	hr {
		border: .5px solid rgba(255,255,255,.15);
		margin: 32px 0px 32px 0px;
	}
	
}