/*
  Styling for basic Leadrilla card. Most content in a dashboard page should go inside a card.

  Font inside of cards should be styled as follows:
  - Title: <h2>
  - Main text: <p>
  - Sub-title: <h3>
  - Small text and disclaimers: .lr-small-text
*/

.card {
  position: relative;
  padding: $lr-padding;
  background-color: $white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  min-height: 150px;
  transition: 0.1s;

  &.disabled {
    color: $grey;
  }

  p a {
    font-weight: 400;
    text-decoration: underline;
  }

  a {
    font-weight: 500;
    font-size: 14px;
  }
}
