.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  z-index: 1000;
  padding: 8px;

  .modal-container {
    background: $white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    border-bottom: 1px solid $light-grey;
    height: 52px;

    h2 {
      float: left;
    }

    span.close {
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .modal-footer {
    border-top: 1px solid $light-grey;
    text-align: right;
  }
}
