/*
All font styling that isn't unique to a certain component should be done in here.
Mobile first then use the media query mixins for other sizes.
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
a,
button,
input,
form,
label,
textarea {
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;

  &.sans-serif {
    font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

a {
  color: $primary;
  font-weight: 700;

  &.white {
    color: $white;
  }

  &.back-link {
    color: $dark-grey;
    font-weight: 400;
    font-size: 14px;
  }

  &.lead-phone {
    color: $green;
    font-size: 18px;
    font-weight: 600;

    @include up('sm') {
      font-size: 20px;
    }
  }

  &.small {
    font-size: 14px !important;
  }
}

label {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}

p {
  line-height: 1.5em;
  font-size: 14px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 16px;

  @include up('sm') {
    font-size: 16px;
    &.small {
      font-size: 14px;
    }
  }

  &.p-large {
    font-size: 18px;
  }

  &.line-height-lg {
    line-height: 2em;
  }

  &.small {
    font-size: 14px!important;
  }
}

.lr-small-text {
  font-size: 13px !important;
  color: $darker-grey;
}

.lr-md-text {
  font-size: 14px !important;
  color: $darker-grey;
}

.lr-large-text {
  font-size: 22px !important;
  line-height: 1.8em;
}

h1 {
  font-size: 26px;
  font-weight: 800;
  padding: 0;
  margin-top: 0;
  margin-bottom: 16px;

  @include up('sm') {
    font-size: 30px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 12px;
}

h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px;

  @include up('sm') {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 4px;
  text-transform: uppercase;

  @include up('sm') {
    font-size: 12px;
    margin-bottom: 6px;
  }
}

.lr-big-number {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.lr-jumbo-txt {
  font-weight: 800;
  font-size: 42px;
  margin: 0;

  @include up(md) {
    font-size: 48px;
  }

  @include up(lg) {
    font-size: 54px;
  }
}

.bold {
  font-weight: 800;
}

.underline {
  text-decoration: underline;
}

.light-grey-text {
  color: $light-grey !important;
}

.grey-text {
  color: $grey !important;
}

.light-green-text {
  color: $light-green !important;
}

.lighter-green-text {
  color: $lighter-green !important;
}

.green-text {
  color: $green !important;
}

.dark-grey-text {
  color: $dark-grey !important;
}

.red-text {
  color: $red !important;
}

.lr-blockquote {
  border: 1px solid $light-grey;
  line-height: 1.4em;
}

.text-link {
  cursor: pointer;
}