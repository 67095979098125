/*
When styling anything in the app this should be the first place you look, can you use these variables?
If you can't think about how you can make whatever you are doing reusable and add it here.
*/

/* Colors */
$primary: #000000;
$white: #ffffff;
$lightest-grey: #f2f2f2;
$lighter-grey: #eaeaea;
$light-grey: #ccc;
$grey: #999999;
$dark-grey: #707070;
$darker-grey: #333333;
$green: #18AA6C;
$light-green: #1DB575;
$lighter-green: #3ADD98;
$blue: #21566a;
$pink: #da3585;
$red: #b30000;
$light-blue: #29ace2;

/* Spacing */
$lr-padding-sm: 8px;
$lr-padding: 16px;
$lr-padding-lg: 24px;
$lr-padding-xl: 32px;
$lr-margin-sm: 8px;
$lr-margin: 16px;
$lr-margin-lg: 24px;
$lr-margin-xl: 32px;

/* Navbar */
$navbar-bg: $primary;
$navbar-bg-light: none;

/* Misc */
$lr-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
$lr-box-shadow-top: 0 -2px 12px rgba(0, 0, 0, 0.2);

/*
Screen Sizes for media queries. Note that these are only to be used to our own classes when we can't determine this with blueprint.css
If these change we must also change the native blueprint.css sizes since these follow their sizing.
*/
$screen-sizes: (
  xl: 1440px,
  lg: 960px,
  md: 720px,
  sm: 480px,
  xs: 0px
);
