.Navbar {
  width: 100vw;
  top: 0px;
  z-index: 2;
  position: absolute;
  padding: 8px;
  background: transparent;
  transition: background .2s ease-in-out;
  -webkit-transition: background .2s ease-in-out;

  .logo * {
    fill: white;
  }

  .nav-items {
    .nav-item {
      font-weight: 800;
      font-size: 16px;
      padding: $lr-padding-sm $lr-padding;
      display: inline-block;
      color: $white;

      &:hover {
        color: $light-grey;
      }
    }
  }

  .logo {
    height: 60px;
    svg {
      width: 140px;
      height: auto;
    }
  }

  .nav-action-btns {
    text-align: right;

    a.nav-action-btn {
      margin-left: $lr-padding-sm;
    }
  }
}
