@import url(https://fonts.googleapis.com/css?family=Assistant:400,700|Playfair+Display:700,900);
/*
This is the main styles file of our applications. 
This is the point of entry and only stylesheet we should be importing in the app.
Anything else needed should be in one of the directories imported below.
*/
/*
This file is used to contain all abstracts imports.
Files inside this folder can contain abstract settings, helpers or functions. They have no direct output.
*/
/*
All fonts should be imported in this file
*/
/*
When styling anything in the app this should be the first place you look, can you use these variables?
If you can't think about how you can make whatever you are doing reusable and add it here.
*/
/* Colors */
/* Spacing */
/* Navbar */
/* Misc */
/*
Screen Sizes for media queries. Note that these are only to be used to our own classes when we can't determine this with blueprint.css
If these change we must also change the native blueprint.css sizes since these follow their sizing.
*/
/*
All Mixins should got in this file.
*/
/* Media Queries */
/*
This file is used to contain all base imports.
Files inside this folder can contain global styles used in the project.
*/
/*
  Custom resets/normalize browser styles
*/
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  padding: 0; }

a {
  text-decoration: none; }

/*
All font styling that isn't unique to a certain component should be done in here.
Mobile first then use the media query mixins for other sizes.
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

p,
a,
button,
input,
form,
label,
textarea {
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif; }
  h1.sans-serif, h2.sans-serif, h3.sans-serif, h4.sans-serif, h5.sans-serif, h6.sans-serif {
    font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; }

a {
  color: #000000;
  font-weight: 700; }
  a.white {
    color: #ffffff; }
  a.back-link {
    color: #707070;
    font-weight: 400;
    font-size: 14px; }
  a.lead-phone {
    color: #18AA6C;
    font-size: 18px;
    font-weight: 600; }
    @media (min-width: 480px) {
      a.lead-phone {
        font-size: 20px; } }
  a.small {
    font-size: 14px !important; }

label {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  margin-bottom: 4px; }

p {
  line-height: 1.5em;
  font-size: 14px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 16px; }
  @media (min-width: 480px) {
    p {
      font-size: 16px; }
      p.small {
        font-size: 14px; } }
  p.p-large {
    font-size: 18px; }
  p.line-height-lg {
    line-height: 2em; }
  p.small {
    font-size: 14px !important; }

.lr-small-text {
  font-size: 13px !important;
  color: #333333; }

.lr-md-text {
  font-size: 14px !important;
  color: #333333; }

.lr-large-text {
  font-size: 22px !important;
  line-height: 1.8em; }

h1 {
  font-size: 26px;
  font-weight: 800;
  padding: 0;
  margin-top: 0;
  margin-bottom: 16px; }
  @media (min-width: 480px) {
    h1 {
      font-size: 30px; } }

h2 {
  font-size: 24px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 12px; }

h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px; }
  @media (min-width: 480px) {
    h3 {
      font-size: 16px;
      margin-bottom: 12px; } }

h4 {
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 4px;
  text-transform: uppercase; }
  @media (min-width: 480px) {
    h4 {
      font-size: 12px;
      margin-bottom: 6px; } }

.lr-big-number {
  font-size: 20px;
  font-weight: 700;
  margin: 0; }

.lr-jumbo-txt {
  font-weight: 800;
  font-size: 42px;
  margin: 0; }
  @media (min-width: 720px) {
    .lr-jumbo-txt {
      font-size: 48px; } }
  @media (min-width: 960px) {
    .lr-jumbo-txt {
      font-size: 54px; } }

.bold {
  font-weight: 800; }

.underline {
  text-decoration: underline; }

.light-grey-text {
  color: #ccc !important; }

.grey-text {
  color: #999999 !important; }

.light-green-text {
  color: #1DB575 !important; }

.lighter-green-text {
  color: #3ADD98 !important; }

.green-text {
  color: #18AA6C !important; }

.dark-grey-text {
  color: #707070 !important; }

.red-text {
  color: #b30000 !important; }

.lr-blockquote {
  border: 1px solid #ccc;
  line-height: 1.4em; }

.text-link {
  cursor: pointer; }

/*
General Layout for the application
*/
html {
  box-sizing: border-box;
  height: 100%; }

*,
:after,
:before {
  box-sizing: inherit; }

body,
#root,
.app {
  height: 100%; }

body,
input,
textarea,
select {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a:hover:not(.btn) {
  color: #707070; }

.lr-page {
  min-height: 100%;
  width: 100%; }

img,
svg {
  max-width: 100%; }

img.lr-logo-med,
.lr-logo-med > svg {
  width: 64px;
  height: auto; }

.lr-top-8 {
  margin-top: 8vh; }

.lr-top-16 {
  margin-top: 16vh; }

/* react-router NavLink active routes */
.lr-active-page-link {
  color: #707070; }

/* fix to remove highlight when clicking divs */
.lr-no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

.lr-hero {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  background: #000000;
  color: #ffffff;
  position: relative;
  top: 0px;
  padding-top: 16vh;
  padding-bottom: 24vh;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%); }
  @media (min-width: 480px) {
    .lr-hero {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%); } }
  @media (min-width: 720px) {
    .lr-hero {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%); } }
  .lr-hero p {
    margin: 36px 0;
    font-size: 20px; }
  .lr-hero .container {
    max-width: 380px; }
    @media (min-width: 960px) {
      .lr-hero .container {
        max-width: 440px; } }

.lr-section {
  padding-top: 100px;
  padding-bottom: 100px; }
  .lr-section.lr-section-slanted {
    padding-top: 180px;
    padding-bottom: 180px; }
  @media (max-width: 480px) {
    .lr-section {
      text-align: center; }
      .lr-section ul,
      .lr-section li {
        text-align: left; } }

.lr-section-slanted {
  -webkit-clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
  clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%); }
  @media (min-width: 480px) {
    .lr-section-slanted {
      -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
      clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%); } }
  @media (min-width: 720px) {
    .lr-section-slanted {
      -webkit-clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
      clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%); } }

.lr-bg-light {
  background: #f2f2f2; }

.lr-bg-white {
  background: #ffffff; }

.lr-bg-primary {
  background: #000000;
  color: #ffffff !important; }

span.icon-warning {
  content: '\26A0'; }

/*
This file is used to contain all component imports.
Files inside this folder should contain all styles relating to a reusable component.
*/
.btn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: 9px 20px;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px; }
  .btn.btn-xs {
    font-size: 11px;
    padding: 4px 20px;
    height: 24px; }
  .btn.btn-sm {
    font-size: 14px;
    padding: 5px 20px;
    height: 32px; }
  .btn.btn-lg {
    font-size: 18px;
    padding: 13px 20px;
    height: 46px; }
  .btn.btn-xl {
    font-size: 22px;
    padding: 12px 20px;
    height: 50px; }

.btn-link {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  background: none;
  cursor: pointer; }
  .btn-link:hover {
    color: #333333; }

.btn-link-inherit-style {
  cursor: pointer; }

.btn-block {
  width: 100%; }

.btn-primary {
  background: #000000;
  color: #ffffff; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:visited {
    background: #000000; }

.btn-success {
  background: #18AA6C;
  color: #ffffff; }
  .btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:visited {
    background: #1DB575; }

.btn-white {
  background: #ffffff;
  color: #000000; }
  .btn-white:hover, .btn-white:active, .btn-white:focus, .btn-white:visited {
    background: #ffffff; }

.btn-primary-bordered {
  background: #000000;
  color: #ffffff;
  outline: 2px solid #ffffff;
  outline-offset: -2px; }
  .btn-primary-bordered:hover, .btn-primary-bordered:active, .btn-primary-bordered:focus, .btn-primary-bordered:visited {
    background: #000000; }

.btn-white-bordered {
  background: #ffffff;
  color: #000000;
  outline: 2px solid #000000;
  outline-offset: -2px; }
  .btn-white-bordered:hover, .btn-white-bordered:active, .btn-white-bordered:focus, .btn-white-bordered:visited {
    background: #ffffff; }

.btn-green {
  background: #18AA6C;
  color: #ffffff; }

.btn-blue {
  background: #21566a;
  color: #ffffff; }

.btn-light-blue {
  background: #29ace2;
  color: #ffffff; }

.btn-pink {
  background: #da3585;
  color: #ffffff; }

.btn-grey {
  background: #ccc;
  color: #000000; }

.btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.5; }

/*
  Styling for basic Leadrilla card. Most content in a dashboard page should go inside a card.

  Font inside of cards should be styled as follows:
  - Title: <h2>
  - Main text: <p>
  - Sub-title: <h3>
  - Small text and disclaimers: .lr-small-text
*/
.card {
  position: relative;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  min-height: 150px;
  -webkit-transition: 0.1s;
  transition: 0.1s; }
  .card.disabled {
    color: #999999; }
  .card p a {
    font-weight: 400;
    text-decoration: underline; }
  .card a {
    font-weight: 500;
    font-size: 14px; }

/*
  Styles for Leadrilla .ghost-card
*/
.ghost-card {
  padding: 16px;
  background-color: #eaeaea;
  min-height: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  text-decoration: none; }
  @media (min-width: 480px) {
    .ghost-card {
      min-height: 150px; } }
  .ghost-card:hover {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15); }
  .ghost-card h2 {
    margin: 0; }

.add-icon {
  background-color: #707070;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 26px;
  height: 26px;
  margin-bottom: 6px;
  border-radius: 50%; }
  @media (min-width: 480px) {
    .add-icon {
      width: 36px;
      height: 36px; } }
  .add-icon span {
    display: block;
    background-color: #ffffff;
    width: 14px;
    height: 2px; }
    @media (min-width: 480px) {
      .add-icon span {
        width: 16px;
        height: 2px; } }
    .add-icon span:after {
      content: '';
      display: block;
      background-color: #ffffff;
      width: 14px;
      height: 2px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
      @media (min-width: 480px) {
        .add-icon span:after {
          width: 16px;
          height: 2px; } }

form label {
  font-size: 12px;
  color: #707070;
  text-transform: uppercase; }

form input:not([type='checkbox']),
form select,
form textarea {
  padding: 13px;
  margin: 6px 0 0 0;
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  color: #ffffff; }
  form input:not([type='checkbox']).has-error,
  form select.has-error,
  form textarea.has-error {
    border: 2px solid red;
    outline-color: red; }
  form input:not([type='checkbox']):disabled,
  form select:disabled,
  form textarea:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }

form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form textarea:-webkit-autofill,
form textarea:-webkit-autofill:hover,
form textarea:-webkit-autofill:focus,
form select:-webkit-autofill,
form select:-webkit-autofill:hover,
form select:-webkit-autofill:focus {
  border: 1px solid rgba(255, 255, 255, 0.75);
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #283784 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent; }

form .error-message {
  color: #eee;
  opacity: 1;
  font-size: 14px;
  display: inline-block;
  float: right;
  height: 0px;
  margin-top: -2px;
  text-align: right;
  max-width: 80%; }
  form .error-message.error-block {
    display: block;
    height: auto;
    float: none;
    text-align: left;
    max-width: none;
    margin: 0px; }

form input[type='checkbox'] {
  display: none; }
  form input[type='checkbox'] + label {
    position: relative;
    text-transform: none;
    padding-left: 30px;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 16px; }
  form input[type='checkbox'] + label:before,
  form input[type='checkbox'] + label:after {
    position: absolute; }
  form input[type='checkbox'] + label:before {
    content: '';
    top: -3px;
    left: 0;
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc; }
  form input[type='checkbox']:checked + label:after {
    content: ''; }
  form input[type='checkbox'] + label:after {
    content: none;
    top: 3px;
    left: 5px;
    display: inline-block;
    height: 6px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

form select {
  padding-right: 15px;
  border-radius: 0;
  -webkit-appearance: none;
  background: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>') no-repeat 96% 50%;
  height: 47px; }

form .form-show-password {
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer; }
  form .form-show-password:hover {
    text-decoration: underline; }

form ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.7);
  opacity: .5;
  /* Firefox */ }

form ::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.7);
  opacity: .5;
  /* Firefox */ }

form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.7);
  opacity: .5;
  /* Firefox */ }

form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.7); }

form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.7); }

/*
Leadrilla dropdown menu designed for use with the navbar
*/
.lr-dropdown-menu {
  height: calc(100vh);
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 16px; }
  .lr-dropdown-menu.close {
    display: none; }
  .lr-dropdown-menu .container {
    height: 100%; }
  .lr-dropdown-menu a {
    font-size: 20px;
    font-weight: 800; }

.lr-burger-btn {
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
  display: block;
  width: 24px;
  height: 2px;
  -webkit-transform: translateY(13px);
          transform: translateY(13px);
  background-color: #ffffff; }
  .lr-burger-btn:before {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    -webkit-transform: translateY(-400%);
            transform: translateY(-400%);
    background-color: #ffffff; }
  .lr-burger-btn:after {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    -webkit-transform: translateY(300%);
            transform: translateY(300%);
    background-color: #ffffff; }
  .lr-burger-btn.close {
    background: transparent; }
    .lr-burger-btn.close:before {
      -webkit-transform: translateY(50%) rotate(45deg);
              transform: translateY(50%) rotate(45deg);
      background-color: black; }
    .lr-burger-btn.close:after {
      -webkit-transform: translateY(-50%) rotate(-45deg);
              transform: translateY(-50%) rotate(-45deg);
      background-color: black; }
  .lr-burger-btn.grey {
    background-color: #707070; }
    .lr-burger-btn.grey:before {
      background-color: #707070; }
    .lr-burger-btn.grey:after {
      background-color: #707070; }

.Navbar {
  width: 100vw;
  top: 0px;
  z-index: 2;
  position: absolute;
  padding: 8px;
  background: transparent;
  transition: background .2s ease-in-out;
  -webkit-transition: background .2s ease-in-out; }
  .Navbar .logo * {
    fill: white; }
  .Navbar .nav-items .nav-item {
    font-weight: 800;
    font-size: 16px;
    padding: 8px 16px;
    display: inline-block;
    color: #ffffff; }
    .Navbar .nav-items .nav-item:hover {
      color: #ccc; }
  .Navbar .logo {
    height: 60px; }
    .Navbar .logo svg {
      width: 140px;
      height: auto; }
  .Navbar .nav-action-btns {
    text-align: right; }
    .Navbar .nav-action-btns a.nav-action-btn {
      margin-left: 8px; }

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  z-index: 1000;
  padding: 8px; }
  .modal-wrapper .modal-container {
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2); }
  .modal-wrapper .modal-header {
    border-bottom: 1px solid #ccc;
    height: 52px; }
    .modal-wrapper .modal-header h2 {
      float: left; }
    .modal-wrapper .modal-header span.close {
      float: right;
      font-size: 20px;
      cursor: pointer; }
  .modal-wrapper .modal-footer {
    border-top: 1px solid #ccc;
    text-align: right; }

.Hero {
  background: #293884;
  padding: 150px 8px 94px 8px;
  background-image: url(/static/media/hero-background-couple-beach.7beb2644.jpg);
  background-size: cover;
  background-position: 64% 60%;
  min-height: 300px;
  color: #ffffff; }
  @media (max-width: 480px) {
    .Hero {
      padding: 124px 8px 56px 8px; } }
  .Hero h1 {
    font-size: 36px; }
    @media (max-width: 720px) {
      .Hero h1 {
        font-size: 28px; } }
  .Hero h2 {
    font-size: 24px; }
    @media (max-width: 720px) {
      .Hero h2 {
        font-size: 20px; } }
  .Hero p {
    font-size: 16px; }
  .Hero hr {
    border: 0.5px solid rgba(255, 255, 255, 0.15);
    margin: 32px 0px 32px 0px; }

.AnnuityLeadForm label {
  color: #ffffff; }

.AnnuityLeadForm form input {
  font-size: 18px; }

.AnnuityLeadForm .btn {
  height: 49px; }

.AnnuityLeadForm .checkbox-selection {
  cursor: pointer; }
  .AnnuityLeadForm .checkbox-selection span.checkbox {
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    cursor: pointer; }
    .AnnuityLeadForm .checkbox-selection span.checkbox.checked {
      background-color: #1DB575;
      border: 1px solid #1DB575;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25); }
  .AnnuityLeadForm .checkbox-selection span.checkbox-label {
    font-size: 18px;
    margin-left: 16px;
    top: -6px;
    position: relative; }
  .AnnuityLeadForm .checkbox-selection:hover span.checkbox {
    background: rgba(255, 255, 255, 0.08); }
    .AnnuityLeadForm .checkbox-selection:hover span.checkbox.checked {
      background-color: #1DB575;
      border: 1px solid #1DB575;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25); }

/*
This file is used to contain all vendor imports.
*/
/*!
    Blueprint CSS 3.1.0
    https://blueprintcss.io
    License MIT 2018
*/
html {
  box-sizing: border-box; }

*,
::after,
::before {
  box-sizing: inherit; }

[bp~='container'] {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: 1000px; }

[bp~='grid'] {
  display: grid !important;
  grid-gap: 16px;
  grid-template-columns: repeat(12, 1fr); }

[bp~='vertical-start'] {
  -webkit-align-items: start;
          align-items: start; }

[bp~='vertical-center'] {
  -webkit-align-items: center;
          align-items: center; }

[bp~='vertical-end'] {
  -webkit-align-items: end;
          align-items: end; }

[bp~='between'] {
  -webkit-justify-content: center;
          justify-content: center; }

[bp~='gap-none'] {
  grid-gap: 0;
  margin-bottom: 0; }

[bp~='gap-column-none'] {
  grid-column-gap: 0; }

[bp~='gap-row-none'] {
  grid-row-gap: 0;
  margin-bottom: 0; }

[bp~='first'] {
  -webkit-order: -1;
          order: -1; }

[bp~='last'] {
  -webkit-order: 12;
          order: 12; }

[bp~='hide'] {
  display: none !important; }

[bp~='show'] {
  display: inline !important;
  display: initial !important; }

[bp~='grid'][bp*='@'],
[bp~='grid'][bp*='@lg'],
[bp~='grid'][bp*='@md'],
[bp~='grid'][bp*='@sm'],
[bp~='grid'][bp*='@xl'] {
  grid-template-columns: 12fr; }

[bp~='10@lg'],
[bp~='10@md'],
[bp~='10@sm'],
[bp~='10@xl'],
[bp~='11@lg'],
[bp~='11@md'],
[bp~='11@sm'],
[bp~='11@xl'],
[bp~='12@lg'],
[bp~='12@md'],
[bp~='12@sm'],
[bp~='12@xl'],
[bp~='1@lg'],
[bp~='1@md'],
[bp~='1@sm'],
[bp~='1@xl'],
[bp~='2@lg'],
[bp~='2@md'],
[bp~='2@sm'],
[bp~='2@xl'],
[bp~='3@lg'],
[bp~='3@md'],
[bp~='3@sm'],
[bp~='3@xl'],
[bp~='4@lg'],
[bp~='4@md'],
[bp~='4@sm'],
[bp~='4@xl'],
[bp~='5@lg'],
[bp~='5@md'],
[bp~='5@sm'],
[bp~='5@xl'],
[bp~='6@lg'],
[bp~='6@md'],
[bp~='6@sm'],
[bp~='6@xl'],
[bp~='7@lg'],
[bp~='7@md'],
[bp~='7@sm'],
[bp~='7@xl'],
[bp~='8@lg'],
[bp~='8@md'],
[bp~='8@sm'],
[bp~='8@xl'],
[bp~='9@lg'],
[bp~='9@md'],
[bp~='9@sm'],
[bp~='9@xl'] {
  grid-column: span 12; }

[bp~='grid'][bp~='1'] {
  grid-template-columns: repeat(12, 1fr); }

[bp~='1'] {
  grid-column: span 1 / span 1; }

[bp~='grid'][bp~='2'] {
  grid-template-columns: repeat(6, 1fr); }

[bp~='2'] {
  grid-column: span 2 / span 2; }

[bp~='grid'][bp~='3'] {
  grid-template-columns: repeat(4, 1fr); }

[bp~='3'] {
  grid-column: span 3 / span 3; }

[bp~='grid'][bp~='4'] {
  grid-template-columns: repeat(3, 1fr); }

[bp~='4'] {
  grid-column: span 4 / span 4; }

[bp~='grid'][bp~='5'] {
  grid-template-columns: repeat(2.4, 1fr); }

[bp~='5'] {
  grid-column: span 5 / span 5; }

[bp~='grid'][bp~='6'] {
  grid-template-columns: repeat(2, 1fr); }

[bp~='6'] {
  grid-column: span 6 / span 6; }

[bp~='grid'][bp~='7'] {
  grid-template-columns: repeat(1.71429, 1fr); }

[bp~='7'] {
  grid-column: span 7 / span 7; }

[bp~='grid'][bp~='8'] {
  grid-template-columns: repeat(1.5, 1fr); }

[bp~='8'] {
  grid-column: span 8 / span 8; }

[bp~='grid'][bp~='9'] {
  grid-template-columns: repeat(1.33333, 1fr); }

[bp~='9'] {
  grid-column: span 9 / span 9; }

[bp~='grid'][bp~='10'] {
  grid-template-columns: repeat(1.2, 1fr); }

[bp~='10'] {
  grid-column: span 10 / span 10; }

[bp~='grid'][bp~='11'] {
  grid-template-columns: repeat(1.09091, 1fr); }

[bp~='11'] {
  grid-column: span 11 / span 11; }

[bp~='grid'][bp~='12'] {
  grid-template-columns: repeat(1, 1fr); }

[bp~='12'] {
  grid-column: span 12 / span 12; }

[bp~='offset-1'] {
  grid-column-start: 1; }

[bp~='offset-2'] {
  grid-column-start: 2; }

[bp~='offset-3'] {
  grid-column-start: 3; }

[bp~='offset-4'] {
  grid-column-start: 4; }

[bp~='offset-5'] {
  grid-column-start: 5; }

[bp~='offset-6'] {
  grid-column-start: 6; }

[bp~='offset-7'] {
  grid-column-start: 7; }

[bp~='offset-8'] {
  grid-column-start: 8; }

[bp~='offset-9'] {
  grid-column-start: 9; }

[bp~='offset-10'] {
  grid-column-start: 10; }

[bp~='offset-11'] {
  grid-column-start: 11; }

[bp~='offset-12'] {
  grid-column-start: 12; }

@media (min-width: 480px) {
  [bp~='grid'][bp~='1@sm'] {
    grid-template-columns: repeat(12, 1fr); }
  [bp~='1@sm'] {
    grid-column: span 1 / span 1; }
  [bp~='grid'][bp~='2@sm'] {
    grid-template-columns: repeat(6, 1fr); }
  [bp~='2@sm'] {
    grid-column: span 2 / span 2; }
  [bp~='grid'][bp~='3@sm'] {
    grid-template-columns: repeat(4, 1fr); }
  [bp~='3@sm'] {
    grid-column: span 3 / span 3; }
  [bp~='grid'][bp~='4@sm'] {
    grid-template-columns: repeat(3, 1fr); }
  [bp~='4@sm'] {
    grid-column: span 4 / span 4; }
  [bp~='grid'][bp~='5@sm'] {
    grid-template-columns: repeat(2.4, 1fr); }
  [bp~='5@sm'] {
    grid-column: span 5 / span 5; }
  [bp~='grid'][bp~='6@sm'] {
    grid-template-columns: repeat(2, 1fr); }
  [bp~='6@sm'] {
    grid-column: span 6 / span 6; }
  [bp~='grid'][bp~='7@sm'] {
    grid-template-columns: repeat(1.71429, 1fr); }
  [bp~='7@sm'] {
    grid-column: span 7 / span 7; }
  [bp~='grid'][bp~='8@sm'] {
    grid-template-columns: repeat(1.5, 1fr); }
  [bp~='8@sm'] {
    grid-column: span 8 / span 8; }
  [bp~='grid'][bp~='9@sm'] {
    grid-template-columns: repeat(1.33333, 1fr); }
  [bp~='9@sm'] {
    grid-column: span 9 / span 9; }
  [bp~='grid'][bp~='10@sm'] {
    grid-template-columns: repeat(1.2, 1fr); }
  [bp~='10@sm'] {
    grid-column: span 10 / span 10; }
  [bp~='grid'][bp~='11@sm'] {
    grid-template-columns: repeat(1.09091, 1fr); }
  [bp~='11@sm'] {
    grid-column: span 11 / span 11; }
  [bp~='grid'][bp~='12@sm'] {
    grid-template-columns: repeat(1, 1fr); }
  [bp~='12@sm'] {
    grid-column: span 12 / span 12; }
  [bp~='offset-1@sm'] {
    grid-column-start: 1; }
  [bp~='offset-2@sm'] {
    grid-column-start: 2; }
  [bp~='offset-3@sm'] {
    grid-column-start: 3; }
  [bp~='offset-4@sm'] {
    grid-column-start: 4; }
  [bp~='offset-5@sm'] {
    grid-column-start: 5; }
  [bp~='offset-6@sm'] {
    grid-column-start: 6; }
  [bp~='offset-7@sm'] {
    grid-column-start: 7; }
  [bp~='offset-8@sm'] {
    grid-column-start: 8; }
  [bp~='offset-9@sm'] {
    grid-column-start: 9; }
  [bp~='offset-10@sm'] {
    grid-column-start: 10; }
  [bp~='offset-11@sm'] {
    grid-column-start: 11; }
  [bp~='offset-12@sm'] {
    grid-column-start: 12; }
  [bp~='hide@sm'] {
    display: none !important; }
  [bp~='show@sm'] {
    display: inline !important;
    display: initial !important; }
  [bp~='first@sm'] {
    -webkit-order: -1;
            order: -1; }
  [bp~='last@sm'] {
    -webkit-order: 12;
            order: 12; } }

@media (min-width: 720px) {
  [bp~='grid'][bp~='1@md'] {
    grid-template-columns: repeat(12, 1fr); }
  [bp~='1@md'] {
    grid-column: span 1 / span 1; }
  [bp~='grid'][bp~='2@md'] {
    grid-template-columns: repeat(6, 1fr); }
  [bp~='2@md'] {
    grid-column: span 2 / span 2; }
  [bp~='grid'][bp~='3@md'] {
    grid-template-columns: repeat(4, 1fr); }
  [bp~='3@md'] {
    grid-column: span 3 / span 3; }
  [bp~='grid'][bp~='4@md'] {
    grid-template-columns: repeat(3, 1fr); }
  [bp~='4@md'] {
    grid-column: span 4 / span 4; }
  [bp~='grid'][bp~='5@md'] {
    grid-template-columns: repeat(2.4, 1fr); }
  [bp~='5@md'] {
    grid-column: span 5 / span 5; }
  [bp~='grid'][bp~='6@md'] {
    grid-template-columns: repeat(2, 1fr); }
  [bp~='6@md'] {
    grid-column: span 6 / span 6; }
  [bp~='grid'][bp~='7@md'] {
    grid-template-columns: repeat(1.71429, 1fr); }
  [bp~='7@md'] {
    grid-column: span 7 / span 7; }
  [bp~='grid'][bp~='8@md'] {
    grid-template-columns: repeat(1.5, 1fr); }
  [bp~='8@md'] {
    grid-column: span 8 / span 8; }
  [bp~='grid'][bp~='9@md'] {
    grid-template-columns: repeat(1.33333, 1fr); }
  [bp~='9@md'] {
    grid-column: span 9 / span 9; }
  [bp~='grid'][bp~='10@md'] {
    grid-template-columns: repeat(1.2, 1fr); }
  [bp~='10@md'] {
    grid-column: span 10 / span 10; }
  [bp~='grid'][bp~='11@md'] {
    grid-template-columns: repeat(1.09091, 1fr); }
  [bp~='11@md'] {
    grid-column: span 11 / span 11; }
  [bp~='grid'][bp~='12@md'] {
    grid-template-columns: repeat(1, 1fr); }
  [bp~='12@md'] {
    grid-column: span 12 / span 12; }
  [bp~='offset-1@md'] {
    grid-column-start: 1; }
  [bp~='offset-2@md'] {
    grid-column-start: 2; }
  [bp~='offset-3@md'] {
    grid-column-start: 3; }
  [bp~='offset-4@md'] {
    grid-column-start: 4; }
  [bp~='offset-5@md'] {
    grid-column-start: 5; }
  [bp~='offset-6@md'] {
    grid-column-start: 6; }
  [bp~='offset-7@md'] {
    grid-column-start: 7; }
  [bp~='offset-8@md'] {
    grid-column-start: 8; }
  [bp~='offset-9@md'] {
    grid-column-start: 9; }
  [bp~='offset-10@md'] {
    grid-column-start: 10; }
  [bp~='offset-11@md'] {
    grid-column-start: 11; }
  [bp~='offset-12@md'] {
    grid-column-start: 12; }
  [bp~='hide@md'] {
    display: none !important; }
  [bp~='show@md'] {
    display: inline !important;
    display: initial !important; }
  [bp~='first@md'] {
    -webkit-order: -1;
            order: -1; }
  [bp~='last@md'] {
    -webkit-order: 12;
            order: 12; } }

@media (min-width: 960px) {
  [bp~='grid'][bp~='1@lg'] {
    grid-template-columns: repeat(12, 1fr); }
  [bp~='1@lg'] {
    grid-column: span 1 / span 1; }
  [bp~='grid'][bp~='2@lg'] {
    grid-template-columns: repeat(6, 1fr); }
  [bp~='2@lg'] {
    grid-column: span 2 / span 2; }
  [bp~='grid'][bp~='3@lg'] {
    grid-template-columns: repeat(4, 1fr); }
  [bp~='3@lg'] {
    grid-column: span 3 / span 3; }
  [bp~='grid'][bp~='4@lg'] {
    grid-template-columns: repeat(3, 1fr); }
  [bp~='4@lg'] {
    grid-column: span 4 / span 4; }
  [bp~='grid'][bp~='5@lg'] {
    grid-template-columns: repeat(2.4, 1fr); }
  [bp~='5@lg'] {
    grid-column: span 5 / span 5; }
  [bp~='grid'][bp~='6@lg'] {
    grid-template-columns: repeat(2, 1fr); }
  [bp~='6@lg'] {
    grid-column: span 6 / span 6; }
  [bp~='grid'][bp~='7@lg'] {
    grid-template-columns: repeat(1.71429, 1fr); }
  [bp~='7@lg'] {
    grid-column: span 7 / span 7; }
  [bp~='grid'][bp~='8@lg'] {
    grid-template-columns: repeat(1.5, 1fr); }
  [bp~='8@lg'] {
    grid-column: span 8 / span 8; }
  [bp~='grid'][bp~='9@lg'] {
    grid-template-columns: repeat(1.33333, 1fr); }
  [bp~='9@lg'] {
    grid-column: span 9 / span 9; }
  [bp~='grid'][bp~='10@lg'] {
    grid-template-columns: repeat(1.2, 1fr); }
  [bp~='10@lg'] {
    grid-column: span 10 / span 10; }
  [bp~='grid'][bp~='11@lg'] {
    grid-template-columns: repeat(1.09091, 1fr); }
  [bp~='11@lg'] {
    grid-column: span 11 / span 11; }
  [bp~='grid'][bp~='12@lg'] {
    grid-template-columns: repeat(1, 1fr); }
  [bp~='12@lg'] {
    grid-column: span 12 / span 12; }
  [bp~='offset-1@lg'] {
    grid-column-start: 1; }
  [bp~='offset-2@lg'] {
    grid-column-start: 2; }
  [bp~='offset-3@lg'] {
    grid-column-start: 3; }
  [bp~='offset-4@lg'] {
    grid-column-start: 4; }
  [bp~='offset-5@lg'] {
    grid-column-start: 5; }
  [bp~='offset-6@lg'] {
    grid-column-start: 6; }
  [bp~='offset-7@lg'] {
    grid-column-start: 7; }
  [bp~='offset-8@lg'] {
    grid-column-start: 8; }
  [bp~='offset-9@lg'] {
    grid-column-start: 9; }
  [bp~='offset-10@lg'] {
    grid-column-start: 10; }
  [bp~='offset-11@lg'] {
    grid-column-start: 11; }
  [bp~='offset-12@lg'] {
    grid-column-start: 12; }
  [bp~='hide@lg'] {
    display: none !important; }
  [bp~='show@lg'] {
    display: inline !important;
    display: initial !important; }
  [bp~='first@lg'] {
    -webkit-order: -1;
            order: -1; }
  [bp~='last@lg'] {
    -webkit-order: 12;
            order: 12; } }

@media (min-width: 1440px) {
  [bp~='grid'][bp~='1@xl'] {
    grid-template-columns: repeat(12, 1fr); }
  [bp~='1@xl'] {
    grid-column: span 1 / span 1; }
  [bp~='grid'][bp~='2@xl'] {
    grid-template-columns: repeat(6, 1fr); }
  [bp~='2@xl'] {
    grid-column: span 2 / span 2; }
  [bp~='grid'][bp~='3@xl'] {
    grid-template-columns: repeat(4, 1fr); }
  [bp~='3@xl'] {
    grid-column: span 3 / span 3; }
  [bp~='grid'][bp~='4@xl'] {
    grid-template-columns: repeat(3, 1fr); }
  [bp~='4@xl'] {
    grid-column: span 4 / span 4; }
  [bp~='grid'][bp~='5@xl'] {
    grid-template-columns: repeat(2.4, 1fr); }
  [bp~='5@xl'] {
    grid-column: span 5 / span 5; }
  [bp~='grid'][bp~='6@xl'] {
    grid-template-columns: repeat(2, 1fr); }
  [bp~='6@xl'] {
    grid-column: span 6 / span 6; }
  [bp~='grid'][bp~='7@xl'] {
    grid-template-columns: repeat(1.71429, 1fr); }
  [bp~='7@xl'] {
    grid-column: span 7 / span 7; }
  [bp~='grid'][bp~='8@xl'] {
    grid-template-columns: repeat(1.5, 1fr); }
  [bp~='8@xl'] {
    grid-column: span 8 / span 8; }
  [bp~='grid'][bp~='9@xl'] {
    grid-template-columns: repeat(1.33333, 1fr); }
  [bp~='9@xl'] {
    grid-column: span 9 / span 9; }
  [bp~='grid'][bp~='10@xl'] {
    grid-template-columns: repeat(1.2, 1fr); }
  [bp~='10@xl'] {
    grid-column: span 10 / span 10; }
  [bp~='grid'][bp~='11@xl'] {
    grid-template-columns: repeat(1.09091, 1fr); }
  [bp~='11@xl'] {
    grid-column: span 11 / span 11; }
  [bp~='grid'][bp~='12@xl'] {
    grid-template-columns: repeat(1, 1fr); }
  [bp~='12@xl'] {
    grid-column: span 12 / span 12; }
  [bp~='offset-1@xl'] {
    grid-column-start: 1; }
  [bp~='offset-2@xl'] {
    grid-column-start: 2; }
  [bp~='offset-3@xl'] {
    grid-column-start: 3; }
  [bp~='offset-4@xl'] {
    grid-column-start: 4; }
  [bp~='offset-5@xl'] {
    grid-column-start: 5; }
  [bp~='offset-6@xl'] {
    grid-column-start: 6; }
  [bp~='offset-7@xl'] {
    grid-column-start: 7; }
  [bp~='offset-8@xl'] {
    grid-column-start: 8; }
  [bp~='offset-9@xl'] {
    grid-column-start: 9; }
  [bp~='offset-10@xl'] {
    grid-column-start: 10; }
  [bp~='offset-11@xl'] {
    grid-column-start: 11; }
  [bp~='offset-12@xl'] {
    grid-column-start: 12; }
  [bp~='hide@xl'] {
    display: none !important; }
  [bp~='show@xl'] {
    display: inline !important;
    display: initial !important; }
  [bp~='first@xl'] {
    -webkit-order: -1;
            order: -1; }
  [bp~='last@xl'] {
    -webkit-order: 12;
            order: 12; } }

[bp~='flex'] {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  display: -webkit-flex;
  display: flex; }

[bp~='fill'] {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-basis: 0%;
          flex-basis: 0%; }

[bp~='fit'] {
  -webkit-flex-basis: auto;
          flex-basis: auto; }

[bp~='float-center'] {
  margin-left: auto;
  margin-right: auto;
  display: block;
  float: none; }

[bp~='float-left'] {
  float: left; }

[bp~='float-right'] {
  float: right; }

[bp~='clear-fix']::after {
  content: '';
  display: table;
  clear: both; }

[bp~='text-left'] {
  text-align: left; }

[bp~='text-right'] {
  text-align: right; }

[bp~='text-center'] {
  text-align: center; }

[bp~='1--max'] {
  max-width: 83.33333px !important; }

[bp~='2--max'] {
  max-width: 166.66667px !important; }

[bp~='3--max'] {
  max-width: 250px !important; }

[bp~='4--max'] {
  max-width: 333.33333px !important; }

[bp~='5--max'] {
  max-width: 416.66667px !important; }

[bp~='6--max'] {
  max-width: 500px !important; }

[bp~='7--max'] {
  max-width: 583.33333px !important; }

[bp~='8--max'] {
  max-width: 666.66667px !important; }

[bp~='9--max'] {
  max-width: 750px !important; }

[bp~='10--max'] {
  max-width: 833.33333px !important; }

[bp~='11--max'] {
  max-width: 916.66667px !important; }

[bp~='12--max'] {
  max-width: 1000px !important; }

[bp~='full-width'] {
  width: 100%; }

@media (max-width: 480px) {
  [bp~='full-width-until@sm'] {
    width: 100% !important;
    max-width: 100% !important; } }

@media (max-width: 720px) {
  [bp~='full-width-until@md'] {
    width: 100% !important;
    max-width: 100% !important; } }

@media (max-width: 960px) {
  [bp~='full-width-until@lg'] {
    width: 100% !important;
    max-width: 100% !important; } }

@media (max-width: 1440px) {
  [bp~='full-width-until@xl'] {
    width: 100% !important;
    max-width: 100% !important; } }

[bp~='margin--xs'] {
  margin: 4px !important; }

[bp~='margin-top--xs'] {
  margin-top: 4px !important; }

[bp~='margin-bottom--xs'] {
  margin-bottom: 4px !important; }

[bp~='margin-right--xs'] {
  margin-right: 4px !important; }

[bp~='margin-left--xs'] {
  margin-left: 4px !important; }

[bp~='padding--xs'] {
  padding: 4px !important; }

[bp~='padding-top--xs'] {
  padding-top: 4px !important; }

[bp~='padding-bottom--xs'] {
  padding-bottom: 4px !important; }

[bp~='padding-right--xs'] {
  padding-right: 4px !important; }

[bp~='padding-left--xs'] {
  padding-left: 4px !important; }

[bp~='margin--sm'] {
  margin: 8px !important; }

[bp~='margin-top--sm'] {
  margin-top: 8px !important; }

[bp~='margin-bottom--sm'] {
  margin-bottom: 8px !important; }

[bp~='margin-right--sm'] {
  margin-right: 8px !important; }

[bp~='margin-left--sm'] {
  margin-left: 8px !important; }

[bp~='padding--sm'] {
  padding: 8px !important; }

[bp~='padding-top--sm'] {
  padding-top: 8px !important; }

[bp~='padding-bottom--sm'] {
  padding-bottom: 8px !important; }

[bp~='padding-right--sm'] {
  padding-right: 8px !important; }

[bp~='padding-left--sm'] {
  padding-left: 8px !important; }

[bp~='margin'] {
  margin: 16px !important; }

[bp~='margin-top'] {
  margin-top: 16px !important; }

[bp~='margin-bottom'] {
  margin-bottom: 16px !important; }

[bp~='margin-right'] {
  margin-right: 16px !important; }

[bp~='margin-left'] {
  margin-left: 16px !important; }

[bp~='padding'] {
  padding: 16px !important; }

[bp~='padding-top'] {
  padding-top: 16px !important; }

[bp~='padding-bottom'] {
  padding-bottom: 16px !important; }

[bp~='padding-right'] {
  padding-right: 16px !important; }

[bp~='padding-left'] {
  padding-left: 16px !important; }

[bp~='margin--lg'] {
  margin: 24px !important; }

[bp~='margin-top--lg'] {
  margin-top: 24px !important; }

[bp~='margin-bottom--lg'] {
  margin-bottom: 24px !important; }

[bp~='margin-right--lg'] {
  margin-right: 24px !important; }

[bp~='margin-left--lg'] {
  margin-left: 24px !important; }

[bp~='padding--lg'] {
  padding: 24px !important; }

[bp~='padding-top--lg'] {
  padding-top: 24px !important; }

[bp~='padding-bottom--lg'] {
  padding-bottom: 24px !important; }

[bp~='padding-right--lg'] {
  padding-right: 24px !important; }

[bp~='padding-left--lg'] {
  padding-left: 24px !important; }

[bp~='margin--none'] {
  margin: 0 !important; }

[bp~='margin-top--none'] {
  margin-top: 0 !important; }

[bp~='margin-bottom--none'] {
  margin-bottom: 0 !important; }

[bp~='margin-right--none'] {
  margin-right: 0 !important; }

[bp~='margin-left--none'] {
  margin-left: 0 !important; }

[bp~='padding--none'] {
  padding: 0 !important; }

[bp~='padding-top--none'] {
  padding-top: 0 !important; }

[bp~='padding-bottom--none'] {
  padding-bottom: 0 !important; }

[bp~='padding-right--none'] {
  padding-right: 0 !important; }

[bp~='padding-left--none'] {
  padding-left: 0 !important; }

.ReactTable {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.ReactTable * {
  box-sizing: border-box; }

.ReactTable .rt-table {
  -webkit-flex: auto 1;
          flex: auto 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto; }

.ReactTable .rt-thead {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none; }

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-thead .rt-tr {
  text-align: center; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer; }

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-thead .rt-th:focus {
  outline: none; }

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible; }

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden; }

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis; }

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7; }

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px; }

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px; }

.ReactTable .rt-tbody {
  -webkit-flex: 99999 1 auto;
          flex: 99999 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05); }

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0; }

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip; }

.ReactTable .rt-tr-group {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }

.ReactTable .rt-tr {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-inline-flex;
  display: inline-flex; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-flex: 1 0;
          flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity; }

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important; }

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px; }

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer; }

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0); }

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10; }

.ReactTable .rt-tfoot {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0; }

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03); }

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05); }

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none; }

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none; }

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff; }

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center; }

.ReactTable .-pagination .-center {
  -webkit-flex: 1.5 1;
          flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap; }

.ReactTable .-pagination .-pageJump {
  display: inline-block; }

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center; }

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px; }

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5); }

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all; }

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%); }

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  -webkit-transition: none !important;
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none; }

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast; }

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important; }

.leaflet-container.leaflet-touch-zoom {
  touch-action: pan-x pan-y; }

.leaflet-container.leaflet-touch-drag {
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom; }

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none; }

.leaflet-container {
  -webkit-tap-highlight-color: transparent; }

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4); }

.leaflet-tile {
  -webkit-filter: inherit;
          filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-pane {
  z-index: 400; }

.leaflet-tile-pane {
  z-index: 200; }

.leaflet-overlay-pane {
  z-index: 400; }

.leaflet-shadow-pane {
  z-index: 500; }

.leaflet-marker-pane {
  z-index: 600; }

.leaflet-tooltip-pane {
  z-index: 650; }

.leaflet-popup-pane {
  z-index: 700; }

.leaflet-map-pane canvas {
  z-index: 100; }

.leaflet-map-pane svg {
  z-index: 200; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity; }

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-interactive {
  cursor: pointer; }

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: grab; }

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none; }

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078a8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px; }

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px; }

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  /* background-image: url(images/layers.png); */
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  /* background-image: url(images/layers-2x.png); */
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* Default icon URLs */
.leaflet-default-icon-path {
  /* background-image: url(images/marker-icon.png); */ }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px; }

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)';
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); }

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto; }

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: ''; }

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px; }

.leaflet-tooltip-top {
  margin-top: -6px; }

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px; }

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff; }

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff; }

.leaflet-tooltip-left {
  margin-left: -6px; }

.leaflet-tooltip-right {
  margin-left: 6px; }

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px; }

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff; }

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff; }

