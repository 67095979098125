.btn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: 9px 20px;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;

  // @include down('sm') {
  //   width: 100%;
  // }

  &.btn-xs {
    font-size: 11px;
    padding: 4px 20px;
    height: 24px;
  }

  &.btn-sm {
    font-size: 14px;
    padding: 5px 20px;
    height: 32px;
  }

  &.btn-lg {
    font-size: 18px;
    padding: 13px 20px;
    height: 46px;
  }

  &.btn-xl {
    font-size: 22px;
    padding: 12px 20px;
    height: 50px;
  }
}

.btn-link {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  color: $primary;
  font-size: 16px;
  font-weight: 600;
  background: none;
  cursor: pointer;

  &:hover {
    color: $darker-grey;
  }
}

.btn-link-inherit-style {
  cursor: pointer;
}

.btn-block {
  width: 100%;
}

.btn-primary {
  background: $primary;
  color: $white;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: $primary;
  }
}

.btn-success {
  background: $green;
  color: $white;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: $light-green;
  }
}

.btn-white {
  background: $white;
  color: $primary;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: $white;
  }
}

.btn-primary-bordered {
  background: $primary;
  color: $white;
  outline: 2px solid $white;
  outline-offset: -2px;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: $primary;
  }
}

.btn-white-bordered {
  background: $white;
  color: $primary;
  outline: 2px solid $primary;
  outline-offset: -2px;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: $white;
  }
}

.btn-green {
  background: $green;
  color: $white;
}

.btn-blue {
  background: $blue;
  color: $white;
}

.btn-light-blue {
  background: $light-blue;
  color: $white;
}

.btn-pink {
  background: $pink;
  color: $white;
}

.btn-grey {
  background: $light-grey;
  color: $primary;
}

.btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
