/*
  Custom resets/normalize browser styles
*/

button {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  // outline: none;
}

a {
  text-decoration: none;
}
