/*
General Layout for the application
*/

html {
  box-sizing: border-box;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body,
#root,
.app {
  height: 100%;
}

body,
input,
textarea,
select {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  &:hover:not(.btn) {
    color: $dark-grey;
  }
}

.lr-page {
  min-height: 100%;
  width: 100%;
}

img,
svg {
  max-width: 100%;
}

img.lr-logo-med,
.lr-logo-med > svg {
  width: 64px;
  height: auto;
}

.lr-top-8 {
  margin-top: 8vh;
}

.lr-top-16 {
  margin-top: 16vh;
}

/* react-router NavLink active routes */
.lr-active-page-link {
  color: $dark-grey;
}

/* fix to remove highlight when clicking divs */
.lr-no-select {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.lr-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $primary;
  color: $white;
  position: relative;
  top: 0px;
  padding-top: 16vh;
  padding-bottom: 24vh;
  padding-left: 8px;
  padding-right: 8px;

  -webkit-clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);

  @include up('sm') {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
  }

  @include up('md') {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  }

  p {
    margin: 36px 0;
    font-size: 20px;
  }

  .container {
    max-width: 380px;

    @include up(lg) {
      max-width: 440px;
    }
  }
}

.lr-section {
  padding-top: 100px;
  padding-bottom: 100px;

  &.lr-section-slanted {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  @include down('sm') {
    text-align: center;

    ul,
    li {
      text-align: left;
    }
  }
}

.lr-section-slanted {
  -webkit-clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
  clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);

  @include up('sm') {
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
  }

  @include up('md') {
    -webkit-clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
    clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
  }
}

.lr-bg-light {
  background: $lightest-grey;
}

.lr-bg-white {
  background: $white;
}

.lr-bg-primary {
  background: $primary;
  color: $white !important;
}
