/*!
    Blueprint CSS 3.1.0
    https://blueprintcss.io
    License MIT 2018
*/
html {
  box-sizing: border-box;
}
*,
::after,
::before {
  box-sizing: inherit;
}
[bp~='container'] {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: 1000px;
}
[bp~='grid'] {
  display: grid !important;
  grid-gap: 16px;
  grid-template-columns: repeat(12, 1fr);
}
[bp~='vertical-start'] {
  align-items: start;
}
[bp~='vertical-center'] {
  align-items: center;
}
[bp~='vertical-end'] {
  align-items: end;
}
[bp~='between'] {
  justify-content: center;
}
[bp~='gap-none'] {
  grid-gap: 0;
  margin-bottom: 0;
}
[bp~='gap-column-none'] {
  grid-column-gap: 0;
}
[bp~='gap-row-none'] {
  grid-row-gap: 0;
  margin-bottom: 0;
}
[bp~='first'] {
  order: -1;
}
[bp~='last'] {
  order: 12;
}
[bp~='hide'] {
  display: none !important;
}
[bp~='show'] {
  display: initial !important;
}
[bp~='grid'][bp*='@'],
[bp~='grid'][bp*='@lg'],
[bp~='grid'][bp*='@md'],
[bp~='grid'][bp*='@sm'],
[bp~='grid'][bp*='@xl'] {
  grid-template-columns: 12fr;
}
[bp~='10@lg'],
[bp~='10@md'],
[bp~='10@sm'],
[bp~='10@xl'],
[bp~='11@lg'],
[bp~='11@md'],
[bp~='11@sm'],
[bp~='11@xl'],
[bp~='12@lg'],
[bp~='12@md'],
[bp~='12@sm'],
[bp~='12@xl'],
[bp~='1@lg'],
[bp~='1@md'],
[bp~='1@sm'],
[bp~='1@xl'],
[bp~='2@lg'],
[bp~='2@md'],
[bp~='2@sm'],
[bp~='2@xl'],
[bp~='3@lg'],
[bp~='3@md'],
[bp~='3@sm'],
[bp~='3@xl'],
[bp~='4@lg'],
[bp~='4@md'],
[bp~='4@sm'],
[bp~='4@xl'],
[bp~='5@lg'],
[bp~='5@md'],
[bp~='5@sm'],
[bp~='5@xl'],
[bp~='6@lg'],
[bp~='6@md'],
[bp~='6@sm'],
[bp~='6@xl'],
[bp~='7@lg'],
[bp~='7@md'],
[bp~='7@sm'],
[bp~='7@xl'],
[bp~='8@lg'],
[bp~='8@md'],
[bp~='8@sm'],
[bp~='8@xl'],
[bp~='9@lg'],
[bp~='9@md'],
[bp~='9@sm'],
[bp~='9@xl'] {
  grid-column: span 12;
}
[bp~='grid'][bp~='1'] {
  grid-template-columns: repeat(12, 1fr);
}
[bp~='1'] {
  grid-column: span 1 / span 1;
}
[bp~='grid'][bp~='2'] {
  grid-template-columns: repeat(6, 1fr);
}
[bp~='2'] {
  grid-column: span 2 / span 2;
}
[bp~='grid'][bp~='3'] {
  grid-template-columns: repeat(4, 1fr);
}
[bp~='3'] {
  grid-column: span 3 / span 3;
}
[bp~='grid'][bp~='4'] {
  grid-template-columns: repeat(3, 1fr);
}
[bp~='4'] {
  grid-column: span 4 / span 4;
}
[bp~='grid'][bp~='5'] {
  grid-template-columns: repeat(2.4, 1fr);
}
[bp~='5'] {
  grid-column: span 5 / span 5;
}
[bp~='grid'][bp~='6'] {
  grid-template-columns: repeat(2, 1fr);
}
[bp~='6'] {
  grid-column: span 6 / span 6;
}
[bp~='grid'][bp~='7'] {
  grid-template-columns: repeat(1.71429, 1fr);
}
[bp~='7'] {
  grid-column: span 7 / span 7;
}
[bp~='grid'][bp~='8'] {
  grid-template-columns: repeat(1.5, 1fr);
}
[bp~='8'] {
  grid-column: span 8 / span 8;
}
[bp~='grid'][bp~='9'] {
  grid-template-columns: repeat(1.33333, 1fr);
}
[bp~='9'] {
  grid-column: span 9 / span 9;
}
[bp~='grid'][bp~='10'] {
  grid-template-columns: repeat(1.2, 1fr);
}
[bp~='10'] {
  grid-column: span 10 / span 10;
}
[bp~='grid'][bp~='11'] {
  grid-template-columns: repeat(1.09091, 1fr);
}
[bp~='11'] {
  grid-column: span 11 / span 11;
}
[bp~='grid'][bp~='12'] {
  grid-template-columns: repeat(1, 1fr);
}
[bp~='12'] {
  grid-column: span 12 / span 12;
}
[bp~='offset-1'] {
  grid-column-start: 1;
}
[bp~='offset-2'] {
  grid-column-start: 2;
}
[bp~='offset-3'] {
  grid-column-start: 3;
}
[bp~='offset-4'] {
  grid-column-start: 4;
}
[bp~='offset-5'] {
  grid-column-start: 5;
}
[bp~='offset-6'] {
  grid-column-start: 6;
}
[bp~='offset-7'] {
  grid-column-start: 7;
}
[bp~='offset-8'] {
  grid-column-start: 8;
}
[bp~='offset-9'] {
  grid-column-start: 9;
}
[bp~='offset-10'] {
  grid-column-start: 10;
}
[bp~='offset-11'] {
  grid-column-start: 11;
}
[bp~='offset-12'] {
  grid-column-start: 12;
}
@media (min-width: 480px) {
  [bp~='grid'][bp~='1@sm'] {
    grid-template-columns: repeat(12, 1fr);
  }
  [bp~='1@sm'] {
    grid-column: span 1 / span 1;
  }
  [bp~='grid'][bp~='2@sm'] {
    grid-template-columns: repeat(6, 1fr);
  }
  [bp~='2@sm'] {
    grid-column: span 2 / span 2;
  }
  [bp~='grid'][bp~='3@sm'] {
    grid-template-columns: repeat(4, 1fr);
  }
  [bp~='3@sm'] {
    grid-column: span 3 / span 3;
  }
  [bp~='grid'][bp~='4@sm'] {
    grid-template-columns: repeat(3, 1fr);
  }
  [bp~='4@sm'] {
    grid-column: span 4 / span 4;
  }
  [bp~='grid'][bp~='5@sm'] {
    grid-template-columns: repeat(2.4, 1fr);
  }
  [bp~='5@sm'] {
    grid-column: span 5 / span 5;
  }
  [bp~='grid'][bp~='6@sm'] {
    grid-template-columns: repeat(2, 1fr);
  }
  [bp~='6@sm'] {
    grid-column: span 6 / span 6;
  }
  [bp~='grid'][bp~='7@sm'] {
    grid-template-columns: repeat(1.71429, 1fr);
  }
  [bp~='7@sm'] {
    grid-column: span 7 / span 7;
  }
  [bp~='grid'][bp~='8@sm'] {
    grid-template-columns: repeat(1.5, 1fr);
  }
  [bp~='8@sm'] {
    grid-column: span 8 / span 8;
  }
  [bp~='grid'][bp~='9@sm'] {
    grid-template-columns: repeat(1.33333, 1fr);
  }
  [bp~='9@sm'] {
    grid-column: span 9 / span 9;
  }
  [bp~='grid'][bp~='10@sm'] {
    grid-template-columns: repeat(1.2, 1fr);
  }
  [bp~='10@sm'] {
    grid-column: span 10 / span 10;
  }
  [bp~='grid'][bp~='11@sm'] {
    grid-template-columns: repeat(1.09091, 1fr);
  }
  [bp~='11@sm'] {
    grid-column: span 11 / span 11;
  }
  [bp~='grid'][bp~='12@sm'] {
    grid-template-columns: repeat(1, 1fr);
  }
  [bp~='12@sm'] {
    grid-column: span 12 / span 12;
  }
  [bp~='offset-1@sm'] {
    grid-column-start: 1;
  }
  [bp~='offset-2@sm'] {
    grid-column-start: 2;
  }
  [bp~='offset-3@sm'] {
    grid-column-start: 3;
  }
  [bp~='offset-4@sm'] {
    grid-column-start: 4;
  }
  [bp~='offset-5@sm'] {
    grid-column-start: 5;
  }
  [bp~='offset-6@sm'] {
    grid-column-start: 6;
  }
  [bp~='offset-7@sm'] {
    grid-column-start: 7;
  }
  [bp~='offset-8@sm'] {
    grid-column-start: 8;
  }
  [bp~='offset-9@sm'] {
    grid-column-start: 9;
  }
  [bp~='offset-10@sm'] {
    grid-column-start: 10;
  }
  [bp~='offset-11@sm'] {
    grid-column-start: 11;
  }
  [bp~='offset-12@sm'] {
    grid-column-start: 12;
  }
  [bp~='hide@sm'] {
    display: none !important;
  }
  [bp~='show@sm'] {
    display: initial !important;
  }
  [bp~='first@sm'] {
    order: -1;
  }
  [bp~='last@sm'] {
    order: 12;
  }
}
@media (min-width: 720px) {
  [bp~='grid'][bp~='1@md'] {
    grid-template-columns: repeat(12, 1fr);
  }
  [bp~='1@md'] {
    grid-column: span 1 / span 1;
  }
  [bp~='grid'][bp~='2@md'] {
    grid-template-columns: repeat(6, 1fr);
  }
  [bp~='2@md'] {
    grid-column: span 2 / span 2;
  }
  [bp~='grid'][bp~='3@md'] {
    grid-template-columns: repeat(4, 1fr);
  }
  [bp~='3@md'] {
    grid-column: span 3 / span 3;
  }
  [bp~='grid'][bp~='4@md'] {
    grid-template-columns: repeat(3, 1fr);
  }
  [bp~='4@md'] {
    grid-column: span 4 / span 4;
  }
  [bp~='grid'][bp~='5@md'] {
    grid-template-columns: repeat(2.4, 1fr);
  }
  [bp~='5@md'] {
    grid-column: span 5 / span 5;
  }
  [bp~='grid'][bp~='6@md'] {
    grid-template-columns: repeat(2, 1fr);
  }
  [bp~='6@md'] {
    grid-column: span 6 / span 6;
  }
  [bp~='grid'][bp~='7@md'] {
    grid-template-columns: repeat(1.71429, 1fr);
  }
  [bp~='7@md'] {
    grid-column: span 7 / span 7;
  }
  [bp~='grid'][bp~='8@md'] {
    grid-template-columns: repeat(1.5, 1fr);
  }
  [bp~='8@md'] {
    grid-column: span 8 / span 8;
  }
  [bp~='grid'][bp~='9@md'] {
    grid-template-columns: repeat(1.33333, 1fr);
  }
  [bp~='9@md'] {
    grid-column: span 9 / span 9;
  }
  [bp~='grid'][bp~='10@md'] {
    grid-template-columns: repeat(1.2, 1fr);
  }
  [bp~='10@md'] {
    grid-column: span 10 / span 10;
  }
  [bp~='grid'][bp~='11@md'] {
    grid-template-columns: repeat(1.09091, 1fr);
  }
  [bp~='11@md'] {
    grid-column: span 11 / span 11;
  }
  [bp~='grid'][bp~='12@md'] {
    grid-template-columns: repeat(1, 1fr);
  }
  [bp~='12@md'] {
    grid-column: span 12 / span 12;
  }
  [bp~='offset-1@md'] {
    grid-column-start: 1;
  }
  [bp~='offset-2@md'] {
    grid-column-start: 2;
  }
  [bp~='offset-3@md'] {
    grid-column-start: 3;
  }
  [bp~='offset-4@md'] {
    grid-column-start: 4;
  }
  [bp~='offset-5@md'] {
    grid-column-start: 5;
  }
  [bp~='offset-6@md'] {
    grid-column-start: 6;
  }
  [bp~='offset-7@md'] {
    grid-column-start: 7;
  }
  [bp~='offset-8@md'] {
    grid-column-start: 8;
  }
  [bp~='offset-9@md'] {
    grid-column-start: 9;
  }
  [bp~='offset-10@md'] {
    grid-column-start: 10;
  }
  [bp~='offset-11@md'] {
    grid-column-start: 11;
  }
  [bp~='offset-12@md'] {
    grid-column-start: 12;
  }
  [bp~='hide@md'] {
    display: none !important;
  }
  [bp~='show@md'] {
    display: initial !important;
  }
  [bp~='first@md'] {
    order: -1;
  }
  [bp~='last@md'] {
    order: 12;
  }
}
@media (min-width: 960px) {
  [bp~='grid'][bp~='1@lg'] {
    grid-template-columns: repeat(12, 1fr);
  }
  [bp~='1@lg'] {
    grid-column: span 1 / span 1;
  }
  [bp~='grid'][bp~='2@lg'] {
    grid-template-columns: repeat(6, 1fr);
  }
  [bp~='2@lg'] {
    grid-column: span 2 / span 2;
  }
  [bp~='grid'][bp~='3@lg'] {
    grid-template-columns: repeat(4, 1fr);
  }
  [bp~='3@lg'] {
    grid-column: span 3 / span 3;
  }
  [bp~='grid'][bp~='4@lg'] {
    grid-template-columns: repeat(3, 1fr);
  }
  [bp~='4@lg'] {
    grid-column: span 4 / span 4;
  }
  [bp~='grid'][bp~='5@lg'] {
    grid-template-columns: repeat(2.4, 1fr);
  }
  [bp~='5@lg'] {
    grid-column: span 5 / span 5;
  }
  [bp~='grid'][bp~='6@lg'] {
    grid-template-columns: repeat(2, 1fr);
  }
  [bp~='6@lg'] {
    grid-column: span 6 / span 6;
  }
  [bp~='grid'][bp~='7@lg'] {
    grid-template-columns: repeat(1.71429, 1fr);
  }
  [bp~='7@lg'] {
    grid-column: span 7 / span 7;
  }
  [bp~='grid'][bp~='8@lg'] {
    grid-template-columns: repeat(1.5, 1fr);
  }
  [bp~='8@lg'] {
    grid-column: span 8 / span 8;
  }
  [bp~='grid'][bp~='9@lg'] {
    grid-template-columns: repeat(1.33333, 1fr);
  }
  [bp~='9@lg'] {
    grid-column: span 9 / span 9;
  }
  [bp~='grid'][bp~='10@lg'] {
    grid-template-columns: repeat(1.2, 1fr);
  }
  [bp~='10@lg'] {
    grid-column: span 10 / span 10;
  }
  [bp~='grid'][bp~='11@lg'] {
    grid-template-columns: repeat(1.09091, 1fr);
  }
  [bp~='11@lg'] {
    grid-column: span 11 / span 11;
  }
  [bp~='grid'][bp~='12@lg'] {
    grid-template-columns: repeat(1, 1fr);
  }
  [bp~='12@lg'] {
    grid-column: span 12 / span 12;
  }
  [bp~='offset-1@lg'] {
    grid-column-start: 1;
  }
  [bp~='offset-2@lg'] {
    grid-column-start: 2;
  }
  [bp~='offset-3@lg'] {
    grid-column-start: 3;
  }
  [bp~='offset-4@lg'] {
    grid-column-start: 4;
  }
  [bp~='offset-5@lg'] {
    grid-column-start: 5;
  }
  [bp~='offset-6@lg'] {
    grid-column-start: 6;
  }
  [bp~='offset-7@lg'] {
    grid-column-start: 7;
  }
  [bp~='offset-8@lg'] {
    grid-column-start: 8;
  }
  [bp~='offset-9@lg'] {
    grid-column-start: 9;
  }
  [bp~='offset-10@lg'] {
    grid-column-start: 10;
  }
  [bp~='offset-11@lg'] {
    grid-column-start: 11;
  }
  [bp~='offset-12@lg'] {
    grid-column-start: 12;
  }
  [bp~='hide@lg'] {
    display: none !important;
  }
  [bp~='show@lg'] {
    display: initial !important;
  }
  [bp~='first@lg'] {
    order: -1;
  }
  [bp~='last@lg'] {
    order: 12;
  }
}
@media (min-width: 1440px) {
  [bp~='grid'][bp~='1@xl'] {
    grid-template-columns: repeat(12, 1fr);
  }
  [bp~='1@xl'] {
    grid-column: span 1 / span 1;
  }
  [bp~='grid'][bp~='2@xl'] {
    grid-template-columns: repeat(6, 1fr);
  }
  [bp~='2@xl'] {
    grid-column: span 2 / span 2;
  }
  [bp~='grid'][bp~='3@xl'] {
    grid-template-columns: repeat(4, 1fr);
  }
  [bp~='3@xl'] {
    grid-column: span 3 / span 3;
  }
  [bp~='grid'][bp~='4@xl'] {
    grid-template-columns: repeat(3, 1fr);
  }
  [bp~='4@xl'] {
    grid-column: span 4 / span 4;
  }
  [bp~='grid'][bp~='5@xl'] {
    grid-template-columns: repeat(2.4, 1fr);
  }
  [bp~='5@xl'] {
    grid-column: span 5 / span 5;
  }
  [bp~='grid'][bp~='6@xl'] {
    grid-template-columns: repeat(2, 1fr);
  }
  [bp~='6@xl'] {
    grid-column: span 6 / span 6;
  }
  [bp~='grid'][bp~='7@xl'] {
    grid-template-columns: repeat(1.71429, 1fr);
  }
  [bp~='7@xl'] {
    grid-column: span 7 / span 7;
  }
  [bp~='grid'][bp~='8@xl'] {
    grid-template-columns: repeat(1.5, 1fr);
  }
  [bp~='8@xl'] {
    grid-column: span 8 / span 8;
  }
  [bp~='grid'][bp~='9@xl'] {
    grid-template-columns: repeat(1.33333, 1fr);
  }
  [bp~='9@xl'] {
    grid-column: span 9 / span 9;
  }
  [bp~='grid'][bp~='10@xl'] {
    grid-template-columns: repeat(1.2, 1fr);
  }
  [bp~='10@xl'] {
    grid-column: span 10 / span 10;
  }
  [bp~='grid'][bp~='11@xl'] {
    grid-template-columns: repeat(1.09091, 1fr);
  }
  [bp~='11@xl'] {
    grid-column: span 11 / span 11;
  }
  [bp~='grid'][bp~='12@xl'] {
    grid-template-columns: repeat(1, 1fr);
  }
  [bp~='12@xl'] {
    grid-column: span 12 / span 12;
  }
  [bp~='offset-1@xl'] {
    grid-column-start: 1;
  }
  [bp~='offset-2@xl'] {
    grid-column-start: 2;
  }
  [bp~='offset-3@xl'] {
    grid-column-start: 3;
  }
  [bp~='offset-4@xl'] {
    grid-column-start: 4;
  }
  [bp~='offset-5@xl'] {
    grid-column-start: 5;
  }
  [bp~='offset-6@xl'] {
    grid-column-start: 6;
  }
  [bp~='offset-7@xl'] {
    grid-column-start: 7;
  }
  [bp~='offset-8@xl'] {
    grid-column-start: 8;
  }
  [bp~='offset-9@xl'] {
    grid-column-start: 9;
  }
  [bp~='offset-10@xl'] {
    grid-column-start: 10;
  }
  [bp~='offset-11@xl'] {
    grid-column-start: 11;
  }
  [bp~='offset-12@xl'] {
    grid-column-start: 12;
  }
  [bp~='hide@xl'] {
    display: none !important;
  }
  [bp~='show@xl'] {
    display: initial !important;
  }
  [bp~='first@xl'] {
    order: -1;
  }
  [bp~='last@xl'] {
    order: 12;
  }
}
[bp~='flex'] {
  flex-wrap: wrap;
  display: flex;
}
[bp~='fill'] {
  flex: 1 1 0%;
  flex-basis: 0%;
}
[bp~='fit'] {
  flex-basis: auto;
}
[bp~='float-center'] {
  margin-left: auto;
  margin-right: auto;
  display: block;
  float: none;
}
[bp~='float-left'] {
  float: left;
}
[bp~='float-right'] {
  float: right;
}
[bp~='clear-fix']::after {
  content: '';
  display: table;
  clear: both;
}
[bp~='text-left'] {
  text-align: left;
}
[bp~='text-right'] {
  text-align: right;
}
[bp~='text-center'] {
  text-align: center;
}
[bp~='1--max'] {
  max-width: 83.33333px !important;
}
[bp~='2--max'] {
  max-width: 166.66667px !important;
}
[bp~='3--max'] {
  max-width: 250px !important;
}
[bp~='4--max'] {
  max-width: 333.33333px !important;
}
[bp~='5--max'] {
  max-width: 416.66667px !important;
}
[bp~='6--max'] {
  max-width: 500px !important;
}
[bp~='7--max'] {
  max-width: 583.33333px !important;
}
[bp~='8--max'] {
  max-width: 666.66667px !important;
}
[bp~='9--max'] {
  max-width: 750px !important;
}
[bp~='10--max'] {
  max-width: 833.33333px !important;
}
[bp~='11--max'] {
  max-width: 916.66667px !important;
}
[bp~='12--max'] {
  max-width: 1000px !important;
}
[bp~='full-width'] {
  width: 100%;
}
@media (max-width: 480px) {
  [bp~='full-width-until@sm'] {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 720px) {
  [bp~='full-width-until@md'] {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 960px) {
  [bp~='full-width-until@lg'] {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 1440px) {
  [bp~='full-width-until@xl'] {
    width: 100% !important;
    max-width: 100% !important;
  }
}
[bp~='margin--xs'] {
  margin: 4px !important;
}
[bp~='margin-top--xs'] {
  margin-top: 4px !important;
}
[bp~='margin-bottom--xs'] {
  margin-bottom: 4px !important;
}
[bp~='margin-right--xs'] {
  margin-right: 4px !important;
}
[bp~='margin-left--xs'] {
  margin-left: 4px !important;
}
[bp~='padding--xs'] {
  padding: 4px !important;
}
[bp~='padding-top--xs'] {
  padding-top: 4px !important;
}
[bp~='padding-bottom--xs'] {
  padding-bottom: 4px !important;
}
[bp~='padding-right--xs'] {
  padding-right: 4px !important;
}
[bp~='padding-left--xs'] {
  padding-left: 4px !important;
}
[bp~='margin--sm'] {
  margin: 8px !important;
}
[bp~='margin-top--sm'] {
  margin-top: 8px !important;
}
[bp~='margin-bottom--sm'] {
  margin-bottom: 8px !important;
}
[bp~='margin-right--sm'] {
  margin-right: 8px !important;
}
[bp~='margin-left--sm'] {
  margin-left: 8px !important;
}
[bp~='padding--sm'] {
  padding: 8px !important;
}
[bp~='padding-top--sm'] {
  padding-top: 8px !important;
}
[bp~='padding-bottom--sm'] {
  padding-bottom: 8px !important;
}
[bp~='padding-right--sm'] {
  padding-right: 8px !important;
}
[bp~='padding-left--sm'] {
  padding-left: 8px !important;
}
[bp~='margin'] {
  margin: 16px !important;
}
[bp~='margin-top'] {
  margin-top: 16px !important;
}
[bp~='margin-bottom'] {
  margin-bottom: 16px !important;
}
[bp~='margin-right'] {
  margin-right: 16px !important;
}
[bp~='margin-left'] {
  margin-left: 16px !important;
}
[bp~='padding'] {
  padding: 16px !important;
}
[bp~='padding-top'] {
  padding-top: 16px !important;
}
[bp~='padding-bottom'] {
  padding-bottom: 16px !important;
}
[bp~='padding-right'] {
  padding-right: 16px !important;
}
[bp~='padding-left'] {
  padding-left: 16px !important;
}
[bp~='margin--lg'] {
  margin: 24px !important;
}
[bp~='margin-top--lg'] {
  margin-top: 24px !important;
}
[bp~='margin-bottom--lg'] {
  margin-bottom: 24px !important;
}
[bp~='margin-right--lg'] {
  margin-right: 24px !important;
}
[bp~='margin-left--lg'] {
  margin-left: 24px !important;
}
[bp~='padding--lg'] {
  padding: 24px !important;
}
[bp~='padding-top--lg'] {
  padding-top: 24px !important;
}
[bp~='padding-bottom--lg'] {
  padding-bottom: 24px !important;
}
[bp~='padding-right--lg'] {
  padding-right: 24px !important;
}
[bp~='padding-left--lg'] {
  padding-left: 24px !important;
}
[bp~='margin--none'] {
  margin: 0 !important;
}
[bp~='margin-top--none'] {
  margin-top: 0 !important;
}
[bp~='margin-bottom--none'] {
  margin-bottom: 0 !important;
}
[bp~='margin-right--none'] {
  margin-right: 0 !important;
}
[bp~='margin-left--none'] {
  margin-left: 0 !important;
}
[bp~='padding--none'] {
  padding: 0 !important;
}
[bp~='padding-top--none'] {
  padding-top: 0 !important;
}
[bp~='padding-bottom--none'] {
  padding-bottom: 0 !important;
}
[bp~='padding-right--none'] {
  padding-right: 0 !important;
}
[bp~='padding-left--none'] {
  padding-left: 0 !important;
}
