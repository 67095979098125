.AnnuityLeadForm {
	label {
		color: #ffffff;
	}

	form {
		input {
			font-size: 18px;
		}
	}

	.btn {
		height: 49px;
	}

	.checkbox-selection {
		cursor: pointer;

		span.checkbox {
			display: inline-block;
			height: 24px;
			width: 24px;
			border-radius: 6px;
			border: 1px solid rgba(255,255,255,.75);
			cursor: pointer;

			&.checked {
				background-color: $light-green;
				border: 1px solid $light-green;
				box-shadow: 1px 1px 4px rgba(0,0,0,.25);
			}
		}

		span.checkbox-label {
			font-size: 18px;
			margin-left: 16px;
			top: -6px;
	    position: relative;
		}

		&:hover span.checkbox {
			background: rgba(255,255,255,.08);

			&.checked {
				background-color: $light-green;
				border: 1px solid $light-green;
				box-shadow: 1px 1px 4px rgba(0,0,0,.25);
			}
		}
	}

}